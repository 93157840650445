// @flow

import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import mapDuck from "../redux/mapDuck";
import { getRivers } from "../redux/firestoreSelector";

function useNavigation() {
  const dispatch = useDispatch();
  const location = useLocation();
  const rivers = useSelector(getRivers);

  useEffect(() => {
    const { pathname } = location;
    dispatch(mapDuck.actions.changeRiver(pathname));
  }, [dispatch, location, rivers]);
}

export default useNavigation;
