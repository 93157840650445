// @flow

import { createSelector, createIdSelector } from "redux-views";

import { getSelectedCatalogId } from "../redux/catalogSelector";
import dayjs from "../utils/dayjs";
import { serviceId, defaultRiverPreviews } from "../config";
import fitBounds from "../utils/fitBounds";

export const getPropRiverId = createIdSelector(({ riverId }) => riverId);

export const getData = (state) => state.firestore.data;

export const getOrdered = (state) => state.firestore.ordered;

const getDatetime = (state) => state.datetime.datetime;

export const getRivers = (state) =>
  state.firestore &&
  state.firestore.data &&
  state.firestore.data["river-ice-rivers"];

export const getSelectedCatalog = createSelector(
  [getData, getSelectedCatalogId],
  (data, catalogId) => {
    const catalog = data && data[catalogId];

    return catalog || {};
  }
);

export const getSelectedItem = createSelector(
  [getSelectedCatalogId, getDatetime, getData],
  (catalogId, datetime, data) => {
    const catalog = data && data[catalogId];
    const item =
      catalog &&
      (Object.values(catalog).find(
        (item) =>
          item && item.properties && item.properties.datetime === datetime
      ) ||
        Object.values(catalog)[Object.values(catalog).length - 1]);

    return item;
  }
);

export const getDatetimes = createSelector([getSelectedCatalog], (catalog) => {
  const times = Object.values(catalog)
    .filter((item) => item && item.properties)
    // Convert python iso string to javascript iso string
    .map(
      (item) =>
        dayjs.utc(item.properties.datetime).format("YYYY-MM-DD") +
        "T00:00:00.00Z"
    );

  return times;
});

export const getDatetimeOrDefault = createSelector(
  [getDatetime, getDatetimes],
  (datetime, datetimes) => {
    if (!datetime) {
      return datetimes[datetimes.length - 1];
    }

    const formattedDatetime = `${datetime.split("Z")[0]}.00Z`;
    const selectedDatetime = datetimes.includes(formattedDatetime)
      ? datetime
      : datetimes[datetimes.length - 1];

    return selectedDatetime;
  }
);

// TODO: Remove hard coded GPD collection ID
export const getGprItems = (state) =>
  state.firestore.ordered["river-ice-gpr-churchill"];

export const getRecentGprItem = createSelector(
  [getGprItems, getDatetimeOrDefault],
  (gprItems, datetime) => {
    if (!gprItems) {
      return null;
    }

    const timeWindow = 10 * 86400;
    const seconds = new Date(datetime || "2020-06-05").getTime() / 1000;

    const recentItems = gprItems.filter((item) => {
      const itemSeconds = new Date(item.properties.datetime).getTime() / 1000;

      const filter =
        itemSeconds > seconds - timeWindow && itemSeconds < seconds + 86400 * 7;

      return filter;
    });
    if (recentItems.length > 0) {
      const recentItem = recentItems[recentItems.length - 1];

      return recentItem;
    } else {
      return null;
    }

    // const geojson = {
    //   type: 'FeatureCollection',
    //   features: recentData.map(datum => toGeoJson(datum))
    // }

    // return geojson
  }
);

export const getIceThickness = (state) =>
  state.firestore.ordered["river-ice-thickness"];

export const getIceThicknessGeoJson = createSelector(
  [getIceThickness, getDatetimeOrDefault],
  (iceThickness, datetime) => {
    if (!iceThickness) {
      return null;
    }

    const timeWindow = 10 * 86400;
    const seconds = new Date(datetime || "2020-06-05").getTime() / 1000;
    const selectedData = iceThickness.filter(
      (item) =>
        item.date.seconds > seconds - timeWindow &&
        item.date.seconds < seconds + 86400 * 4
    );
    const recentData = selectedData.slice(selectedData.length - 4);

    const geojson = {
      type: "FeatureCollection",
      features: recentData.map((datum) => toGeoJson(datum)),
    };

    return geojson;
  }
);

function toGeoJson(data) {
  const geojson = {
    type: "Feature",
    geometry: {
      type: "Point",
      coordinates: [data["geometry"].longitude, data["geometry"].latitude],
    },
    properties: {
      ...data,
      date: dayjs.utc(data["date"].seconds * 1000).format("YYYY-MM-DD"),
    },
  };

  return geojson;
}

export const getService = (state) =>
  state.firestore &&
  state.firestore.data &&
  state.firestore.data["river-ice-services"] &&
  state.firestore.data["river-ice-services"][serviceId];

export const getServiceRivers = createSelector([getService], (service) => {
  const serviceRivers = service && service.rivers;

  return serviceRivers || [];
});

export const getServiceLogoUri = createSelector([getService], (service) => {
  const serviceLogoUri = service && service.logoUri;

  return serviceLogoUri;
});

export const getServiceLogoHref = createSelector([getService], (service) => {
  const serviceLogoHref = service && service.logoHref;

  return serviceLogoHref;
});

export const getMapConfigs = createSelector(
  [getServiceRivers, getOrdered, getRivers],
  (serviceRivers, ordered, rivers) => {
    if (!serviceRivers || !rivers || !ordered) {
      return [];
    }

    const mapConfigs = defaultRiverPreviews
      .filter((river) => serviceRivers.includes(river.id))
      .map((river) => {
        const catalogId = rivers[river.id]["catalogs"][0];
        const items = ordered[catalogId];
        if (!items) {
          return river;
        } else {
          const item = items[items.length - 1];
          const url =
            item && item.assets ? item.assets["vector_tiles"].href : river.url;
          const viewport =
            item && item.bbox ? fitBounds({ bounds: item.bbox }) : {};
          return { ...river, url, viewport };
        }
      });

    return mapConfigs || [];
  }
);

export const getServiceTitle = createSelector([getService], (service) => {
  if (!service) {
    return "";
  }

  const { title } = service;

  return title || "";
});

export const getRiverFromId = createSelector(
  [getRivers, getPropRiverId, getServiceRivers],
  (rivers, riverId, serviceRivers) => {
    // Handle special case of only one river skipping the
    // RiverSelector route
    if (!riverId && serviceRivers && serviceRivers.length === 1) {
      return rivers[serviceRivers[0]] || {};
    }
    const river = rivers && rivers[riverId];

    return river || {};
  }
);

export const getCatalogsFromId = createSelector(
  [getRiverFromId, getPropRiverId],
  (river, riverId) => {
    if (!river) {
      return [];
    }

    const { catalogs } = river;

    return catalogs;
  }
);

export const getCatalogsFromServiceId = createSelector(
  [getServiceRivers, getRivers],
  (serviceRivers, rivers) => {
    if (!serviceRivers || !rivers) {
      return [];
    }
    const catalogs = serviceRivers.reduce((acc, serviceRiver) => {
      const river = rivers[serviceRiver];
      const { catalogs } = river;

      return [...acc, ...catalogs];
    }, []);

    return catalogs;
  }
);

export const getAvailableLayers = createSelector(
  [getData, getSelectedCatalogId, getRiverFromId, getPropRiverId],
  (data, catalogId, river, riverId) => {
    const { catalogs } = river;

    const layers = data && data["river-ice-layers"];

    if (layers) {
      const availableLayers = catalogs
        .map((id) => layers[id])
        .filter((layer) => layer.type === "vector");

      return availableLayers;
    } else {
      return [];
    }
  }
);

export const getRenderHomeButton = createSelector(
  [getServiceRivers],
  (rivers) => {
    const renderHomeButton = rivers && rivers.length > 1;

    return renderHomeButton;
  }
);
