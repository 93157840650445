// @flow

import { createSlice } from "@reduxjs/toolkit";
import debugFactory from "debug";

const debug = debugFactory("app:redux:catalogDuck");

const initialState = {
  catalogs: [
    "river-ice-classification",
    "river-ice-change",
    "river-ice-thickness",
  ],
  selected: "river-ice-classification",
};

function setSelected(state, action) {
  const { payload } = action;

  debug("setSelected", payload);

  state.selected = payload;
}

const catalogDuck = createSlice({
  name: "catalog",
  initialState,
  reducers: {
    setSelected,
  },
});

export default catalogDuck;
