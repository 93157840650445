// service id
export const serviceId = process.env.REACT_APP_SERVICE_ID;
// export const serviceId = 'churchill'
// export const serviceId = 'exploits'
// export const serviceId = 'yukon'
if (!serviceId) {
  console.error(
    'Service ID not found in environment variable. Please set `REACT_APP_SERVICE_ID` to a service ID (e.g. "churchill", or "new-brunswick").'
  );
}
export const includeIceFront = Boolean(process.env.REACT_APP_ICE_FRONT);

// Testing
export const testVectorUrl =
  "https://river-ice.s3.amazonaws.com/churchill/tiles/2018-11-22_CHU_CHG_R2/{z}/{x}/{y}.pbf";

// Firebase
export const apiKey = process.env.REACT_APP_API_KEY;
export const authDomain = process.env.REACT_APP_AUTH_DOMAIN;
export const databaseURL = process.env.REACT_APP_DATABASE_URL;
export const projectId = process.env.REACT_APP_PROJECT_ID;
export const storageBucket = process.env.REACT_APP_STORAGE_BUCKET;
export const messagingSenderId = process.env.REACT_APP_MESSAGING_SENDER_ID;
export const appId = process.env.REACT_APP_APP_ID;
export const firebaseConfig = {
  apiKey,
  authDomain,
  databaseURL,
  projectId,
  storageBucket,
  messagingSenderId,
};

// Mapbox
export const mapboxAccessToken = process.env.REACT_APP_MAPBOX_ACCESS_TOKEN;

// Sentry
export const sentryDsn = process.env.REACT_APP_SENTRY_DSN;

// Default map values
export const defaultViewport = {
  latitude: 53.28,
  longitude: -60.43,
  zoom: 10.5,
};

// Legend values
export let classificationLegend = [
  {
    value: 31,
    color: "#004D95",
    label: "Open water",
    title: "Open water",
    strokeWidth: 10,
  },
  {
    value: 32,
    color: "#60C0F3",
    label: "Wet ice or pooling water on ice",
    title: "Wet ice or pooling water on ice",
    strokeWidth: 10,
  },
  {
    value: 33,
    color: "#FDFA00",
    label: "Smooth ice",
    title: "Smooth ice",
    strokeWidth: 10,
  },
  {
    value: 34,
    color: "#EB3D00",
    label: "Rough ice",
    title: "Rough ice",
    strokeWidth: 10,
  },
];

if (serviceId === "exploits") {
  classificationLegend = classificationLegend.filter(
    ({ value }) => value !== 32
  );
}

export const changeLegend = [
  {
    value: -2,
    color: "#0001FC",
    label: "Much less ice",
    title: "Much less ice",
  },
  { value: -1, color: "#31CCFB", label: "Less ice", title: "Less ice" },
  { value: 0, color: "#009B01", label: "No change", title: "No change" },
  { value: 1, color: "#FDFE00", label: "More ice", title: "More ice" },
  {
    value: 2,
    color: "#FE0002",
    label: "Much more ice",
    title: "Much more ice",
  },
];

export const sandBarLegend = [
  {
    value: 31,
    color: "#004D95",
    label: "Open water",
    title: null,
    strokeWidth: 10,
  },
  {
    value: 32,
    color: "#38A700",
    label: "Vegetated",
    title: null,
    strokeWidth: 10,
  },
  {
    value: 33,
    color: "#A87001",
    label: "Unvegetated",
    title: "Unvegetated",
    strokeWidth: 10,
  },
  {
    value: 34,
    color: "#38A700",
    label: "Vegetated",
    title: "Vegetated",
    strokeWidth: 10,
  },
];

export const iceChartLegend = [
  {
    value: 0,
    color: "#ADD8E6",
    label: "0% to 10%",
    title: "Easy navigation",
    strokeWidth: 10,
  },
  {
    value: 1,
    color: "#008000",
    label: "10% to 30%",
    title: "Easy navigation",
    strokeWidth: 10,
  },
  {
    value: 40,
    color: "#FFFF00",
    label: "40% to 60%",
    title: "Difficult navigation",
    strokeWidth: 10,
  },
  {
    value: 70,
    color: "#FFA500",
    label: "70% to 80%",
    title: "Difficult navigation",
    strokeWidth: 10,
  },
  {
    value: 90,
    color: "#FF0000",
    label: "90% to 100%",
    title: "Severe navigation",
    strokeWidth: 10,
  },
];

// River map configurations
export const classificationPaint = {
  "fill-opacity": 1,
  "fill-color": [
    "interpolate",
    ["linear"],
    ["to-number", ["get", "gridcode"]],
    ...[].concat(
      ...classificationLegend.map((item) => [item.value, item.color])
    ),
  ],
  "fill-outline-color": "rgba(0,0,0,0)",
};

// River map configurations
export const sandBarPaint = {
  "fill-opacity": 1,
  "fill-color": [
    "interpolate",
    ["linear"],
    ["to-number", ["get", "gridcode"]],
    ...[].concat(...sandBarLegend.map((item) => [item.value, item.color])),
  ],
  "fill-outline-color": "rgba(0,0,0,0)",
};

export const frtPaint = {
  "line-opacity": 1,
  "line-color": "red",
};

export const defaultRiverPreviews = [
  {
    name: "Churchill",
    id: "churchill",
    url: "https://river-ice.s3.amazonaws.com/churchill/tiles/2020-04-09_CHU_CLS/{z}/{x}/{y}.pbf",
    paint: classificationPaint,
  },
  {
    name: "Miramichi",
    id: "miramichi",
    url: "https://river-ice.s3.amazonaws.com/churchill/tiles/2020-03-02_CHU/{z}/{x}/{y}.pbf",
    paint: classificationPaint,
  },
  {
    name: "Nashwaak",
    id: "nashwaak",
    url: "https://river-ice.s3.amazonaws.com/churchill/tiles/2020-01-02_CHU/{z}/{x}/{y}.pbf",
    paint: classificationPaint,
  },
  {
    name: "Saint-Jean",
    id: "saint-jean",
    url: "https://river-ice.s3.amazonaws.com/churchill/tiles/2019-05-30_CHU/{z}/{x}/{y}.pbf",
    paint: classificationPaint,
  },
  {
    name: "Tobique",
    id: "tobique",
    url: "https://river-ice.s3.amazonaws.com/churchill/tiles/2019-04-25_CHU/{z}/{x}/{y}.pbf",
    paint: classificationPaint,
  },
  {
    name: "Ristigouche",
    id: "ristigouche",
    url: "https://river-ice.s3.amazonaws.com/churchill/tiles/2018-12-26_CHU/{z}/{x}/{y}.pbf",
    paint: classificationPaint,
  },
  {
    name: "Madawaska",
    id: "madawaska",
    url: "https://river-ice.s3.amazonaws.com/churchill/tiles/2020-05-03_CHU_CLS_R2/{z}/{x}/{y}.pbf",
    paint: classificationPaint,
  },
];
