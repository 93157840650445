// @flow

import React, { type Node } from "react";
import { Provider } from "react-redux";
import "typeface-roboto";
import { ThemeProvider } from "@material-ui/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import { SnackbarProvider } from "notistack";

import FirebaseProvider from "./redux/firebaseProvider";
import defaultStore from "./redux/store";
import defaultTheme from "./theme";

const ProviderWrapper = ({
  children,
  store = defaultStore,
  theme = defaultTheme,
}: {
  +children: Node,
  store?: {},
  theme?: {},
}) => (
  <Provider store={defaultStore}>
    <FirebaseProvider store={defaultStore}>
      <ThemeProvider theme={defaultTheme}>
        <SnackbarProvider
          anchorOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
        >
          <CssBaseline />
          {children}
        </SnackbarProvider>
      </ThemeProvider>
    </FirebaseProvider>
  </Provider>
);

export default ProviderWrapper;
