// @flow

import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  classification: {
    type: "fill",
    "source-layer": "data",
  },
  change: {
    type: "fill",
    "source-layer": "data",
  },
  frt: {
    type: "line",
    "source-layer": "data",
  },
  "sand-bar": {
    type: "fill",
    "source-layer": "data",
  },
};

function setLayer(state, action) {
  const { id, layer } = action;

  state[id] = layer;
}

const layerDuck = createSlice({
  name: "layer",
  initialState,
  reducers: {
    setLayer,
  },
});

export default layerDuck;
