// @flow

import { ofType } from "redux-observable";
import { mergeMap } from "rxjs/operators";

import mapDuck from "../redux/mapDuck";
import { getRiverFromId } from "../redux/firestoreSelector";
import fitBounds from "../utils/fitBounds";

const mapEpic = (action$, state$) =>
  action$.pipe(
    ofType(mapDuck.actions.changeRiver),
    mergeMap((action) => {
      const { payload } = action;
      const state = state$.value;
      const riverId = payload.slice(1);
      const river = getRiverFromId(state, { riverId });
      const { bounds } = river;

      if (bounds) {
        const { longitude, latitude, zoom } = fitBounds({ bounds, divisor: 2 });
        const multiplier = 1.01;
        const maxBounds = [
          bounds[0] * multiplier,
          bounds[1] * (1 / multiplier),
          bounds[2] * (1 / multiplier),
          bounds[3] * multiplier,
        ];

        return [
          mapDuck.actions.changeViewport({ latitude, longitude, zoom }),
          mapDuck.actions.setMaxBounds(maxBounds),
          mapDuck.actions.setMinZoom(zoom - 2.5),
        ];
      } else {
        return [{ type: "EMPTY" }];
      }
    })
  );

export default mapEpic;
