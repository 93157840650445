import { createSlice } from "@reduxjs/toolkit";

function toggleChartOpen(state) {
  const { chartOpen } = state;

  return { ...state, chartOpen: !chartOpen };
}

const auth = createSlice({
  name: "settings",
  initialState: {
    chartOpen: true,
  },
  reducers: {
    toggleChartOpen,
  },
});

export default auth;
