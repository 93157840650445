import { createSlice } from "@reduxjs/toolkit";

function changeHover(state, action) {
  const { payload } = action;

  return { ...state, ...payload };
}

const hover = createSlice({
  name: "hover",
  initialState: {
    properties: null,
    x: null,
    y: null,
    lng: null,
    lat: null,
    modal: false,
  },
  reducers: {
    changeHover,
  },
});

export default hover;
