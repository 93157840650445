// @flow

import React from "react";
import { BrowserRouter as Router } from "react-router-dom";
import { useFirestoreConnect } from "react-redux-firebase";

import RoutesContainer from "../navigation/RoutesContainer";

function NavigationContainer() {
  useFirestoreConnect([
    "river-ice-services",
    "river-ice-rivers",
    "river-ice-layers",
  ]);

  return (
    <Router>
      <RoutesContainer />
    </Router>
  );
}

export default NavigationContainer;
