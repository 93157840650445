// @flow

import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  datetime: undefined,
};

function setDatetime(state, action) {
  const { payload } = action;

  state.datetime = payload;
}

const datetimeDuck = createSlice({
  name: "datetime",
  initialState,
  reducers: {
    setDatetime,
  },
});

export default datetimeDuck;
