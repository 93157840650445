// @flow

import React from "react";
import { Routes, Route } from "react-router-dom";
import { useSelector } from "react-redux";
import loadable from "@loadable/component";

import {
  getServiceRivers,
  getServiceLogoUri,
  getServiceLogoHref,
} from "../redux/firestoreSelector";
import useNavigation from "../hooks/useNavigation";

const LoadingRoute = loadable(() => import("../routes/LoadingRoute"));
const RiverSelectorRoute = loadable(() =>
  import("../routes/RiverSelectorRoute")
);
const MapRoute = loadable(() => import("../routes/MapRoute"));

function RoutesContainer() {
  useNavigation();
  const rivers = useSelector(getServiceRivers);
  const logoUri = useSelector(getServiceLogoUri);
  const logoHref = useSelector(getServiceLogoHref);

  // If the length of rivers is 0, then we assume we are still loading.
  // If length of rivers is 1, we render the map route.
  // Otherwise, we render the river selector route.
  // If a river id param is specified, we show that map route.
  return (
    <Routes>
      {rivers.length === 0 ? (
        <Route path="/" element={<LoadingRoute />} />
      ) : rivers.length === 1 ? (
        <Route
          path="/"
          element={<MapRoute logoUri={logoUri} logoHref={logoHref} />}
        />
      ) : (
        <Route
          path="/"
          element={<RiverSelectorRoute logoUri={logoUri} logoHref={logoHref} />}
        />
      )}
      <Route
        path=":id"
        element={<MapRoute logoUri={logoUri} logoHref={logoHref} />}
      />
    </Routes>
  );
}

export default RoutesContainer;
